import React from 'react';
// Components
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';

const DesktopView = loadable(() => import('./DesktopView'));
const MobileView = loadable(() => import('./MobileView'));

export default function CookiePolicyPopup() {
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);

  const View = isRealPhone ? MobileView : DesktopView;

  return <View />;
}
